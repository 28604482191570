#qty-add-cart{
	border: 1px solid transparent;
  border-top: none;
  height: 19px;
  padding: 10px 10px;
  text-align: center;
}
#qty-add-cart-label{
	/*
		display: none;
	*/
}

[id^=inpCant] {
    text-align: center;
}
#orderBySel {
    padding-top: 10px;
    background-color: #efefef;
    padding-bottom: 10px;
    color: #0b3196;
    font-weight: 500;
    font-family: "Centrale Sans Medium";
}
#filterBySel::-ms-expand {
    display: none;
}
#page-size {
    padding-top: 10px;
    background-color: #efefef;
    padding-bottom: 10px;
    color: #0b3196;
    font-weight: 500;
    font-family: "Centrale Sans Medium";
}