.splide__pagination__page.is-active {
  background: #00e500 !important;
  color: #00e500;
  width: 7px !important;
  height: 7px !important;
}
.splide__pagination__page {
  width: 7px !important;
  height: 7px !important;
  color: #fff;
}
.splide__pagination {
  display: flex !important;
  justify-content: flex-start;
  padding-left: 45px;
}
.splide__arrow {
  background-color: transparent !important;
  border: 3px solid #F2F2F2 !important;
  width: 34px;
  height: 34px;
}
.splide__arrow svg {
  fill: #f2f2f2;
}
.splide__arrow:hover {
  border: 3px solid #00e500 !important;
}
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: 1px solid #40df00 !important;
}
