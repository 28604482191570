.faqContainer {
  
}
.faqContainer .title {
  font-family: "M PLUS 2", sans-serif;
  font-weight: 500;
  font-size: 36px;
  padding: 10px 0;
  text-align: center;
}
.faqContainer .faqQuestion {
  display: flex;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%); */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  height: 54px;
  padding: 5px 15px;
  background-color: #fff;
  font-family: "M PLUS 2", sans-serif;
  font-weight: 800;
  font-size: 19px;
  cursor: pointer;
  color: #004586;
}
.faqContainer .faqQuestion.active {
  color: #40df00;
}
.faqContainer .faqAnswer {
  display: none;
  padding: 15px 15px;
  background-color: #fff;
  font-family: "M PLUS 2", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.faqQuestion.active + .faqAnswer {
  display: block;
}
.faqQuestion .fa-chevron-up {
  display: none;
}
.faqQuestion.active .fa-chevron-up {
  display: block;
  position: absolute;
  right: 15px;
}
.faqQuestion .fa-chevron-down {
  position: absolute;
  right: 15px;
}
.faqQuestion.active .fa-chevron-down {
  display: none;
}
