/* steps */
.MuiStepIcon-root.MuiStepIcon-completed,
.MuiStepLabel-label.MuiStepLabel-completed {
  color: #40df00 !important;
}

.MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-family: "Centrale Sans Medium";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  color: #9b9b9b;
  font-weight: 300;
}

.MuiStepLabel-labelContainer .MuiStepLabel-label.MuiStepLabel-active {
  color: #0b3196;
  font-weight: 500;
}

/*qty input style*/
.number-input input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.number-input input[type="number"]::-webkit-inner-spin-button,
.number-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.number-input {
  margin-bottom: 3rem;
}
.number-input button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  position: relative;
  top: 5px;
}
@media screen and (max-width: 600px) {
  .number-input button {
    top: 0px;
  }
}
.number-input .plusButton {
  background-color: #0c2d9c;
}
.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: "";
  height: 2px;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type="number"] {
  text-align: center;
}
.number-input.number-input {
  border: 1px solid #ced4da;
  background-color: #ffffff;
  display: inline-flex;
  margin: auto;
}
.number-input.number-input button {
  width: 2.6rem;
  height: 0.7rem;
}
.number-input.number-input button.minus {
  padding-left: 10px;
}
.number-input.number-input button:before,
.number-input.number-input button:after {
  width: 0.7rem;
}
.number-input.number-input button.plus:before,
.number-input.number-input button.plus:after {
  background-color: #ffffff;
}
.number-input.number-input button.minus:before,
.number-input.number-input button.minus:after {
  background-color: #0b3196;
}
.number-input.number-input input[type="number"] {
  max-width: 4rem;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-width: 0 1px;
  font-size: 1rem;
  height: 2rem;
  color: #495057;
}

/*selects*/
/* .MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 32px;
  border: 1px solid #0b3196;
  border-radius: 2px;
  background-color: #ffffff;
} */

/*primer step productos list mobile*/
.prodNameMob {
  color: #000000;
  font-family: "Centrale Sans Medium";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: justify;
}

.productGrid {
  margin: 0;
  padding: 10px;
  border: 1px solid #eaeaea;
}

.totalPriceProd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeRadio {
  color: #0b3196;
}
