/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #9b9b9b;
    border-radius: 10px;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #0b3196;
    }
