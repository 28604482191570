.App {
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .seperator {
    width: 100%;
  }
  

  .tab-header {
    display: inline-block;
    padding: 10px;
    border-bottom: 4px solid #00FF00; /* Línea verde */
  
    font-size: 22px;

    font-family: 'Centrale Sans Medium';
}

.related-products-header {
    padding: 10px;
    /* Línea verde */
    font-size: 22px;
    margin: 10px 50px;
    font-family: 'Centrale Sans Medium';
}


.linea-tab{
  border-bottom:4px solid #40df00;
 padding-top: 10px;

}
  .controls-wrapper {
    width: 100%;
  }
  
  .rec-arrow {
    border: none !important;
    box-shadow: none !important;
  }

  @media (min-width: 1920px) {
    .makeStyles-container-103 {
        width: 100% !important;
    }
}
  
  .carousel-wrapper {
    width: 100%;
  }
  
  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px rgba(238, 130, 238, 0);
  }
  
  /* my custom styles carousel */
  
  .rec.rec-pagination {
    display: none;
  }
  
  .rec.rec-item-wrapper div {
    background-color: transparent;
  }
  
  .rec.rec-arrow {
    border: 2px solid #40df00;
    background-color: transparent;
  }
  
  .rec.rec-arrow:disabled {
    border: 2px solid transparent;
  }
  
  .rec.rec-arrow:hover:enabled, .rec.rec-arrow:focus:enabled {
    background-color: transparent;
    color: #444444;
  }
  
  .showImg {
    display: inline-block !important;
  }
  
  .hideImg {
    display: none;
  }
  