.container-homes {
    z-index: 999;
    position: fixed;
    box-shadow: -5px 0px 10px 1px #aaaaaa;
    backdrop-filter: blur(7px);
    background-color: #0000000f;
  }

  
  .containers {
    position: fixed;
    z-index: 9999999999999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    color: black;
    text-align: center;
  
    opacity: 1;
    transform: scale(1);
    transition: all 500ms ease;
  
    display: grid;
    place-content: center;
  
    img { 
      width: 150px;
      margin-bottom: 3rem;
    }
  }
  
  .hides {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2);
    pointer-events: none;
  
  
  }

  