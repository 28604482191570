#inpUser{
	background-color: #c4ced8;
	text-align: center;
	font-family: "Centrale Sans Medium";
}
#inpPass{
	background-color: #c4ced8;
	text-align: center;
	font-family: "Centrale Sans Medium";
}
#inpUser-label{
  width: 100%;
  text-align: center;
  font-family: "Centrale Sans Medium";
}
#inpPass-label{
  width: 100%;
  text-align: center;
  font-family: "Centrale Sans Medium";
}
#inp01{
	background-color: #c4ced8;
	font-family: "Centrale Sans Medium";
}