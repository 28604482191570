.carousel-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: white;
  }
  
  .product-card {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    position: relative;
    height: 390px;
  }
  
  .product-image {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-bottom: 10px;
    margin-left: 45px;
   
  }
  
  .product-details {
    padding: 0 10px;
  }
  
  .product-brand {
    color: #0B3196;
    font-size: 16px;
    font-family: 'Centrale Sans Regular';
    font-weight: 400;
    text-decoration: none;
  }
  
  .product-name {
    color: #000000;
    font-size: 15px;
    margin-top: 0;
    height: 80px;
    text-align: justified;
    font-family: 'Centrale Sans Medium';
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
    text-decoration: none;
  }
  
  .price {
    margin-top: 10px;
  }
  
  .current-price {
    font-size: 1.2em;
    font-weight: bold;
    color: #0B3196;
  }
  
  .old-price {
    text-decoration: line-through;
    color: #888;
    margin-left: 75px;
 
  }
  
  .add-to-cart {
    background-color: #0C2D9C;
    color: white;
    padding: 10px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    width: 100%;
  }
  
  .carousel-arrow {
    margin-top: 188px;
    transform: translateY(-50%);
    background-color: white;
    border: 1px solid #ddd;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    font-size: 24px;
    color:gray;
  }
  
  .carousel-arrow.next {
    right: -15px;
    
  }
  .carousel-arrow.next:hover{
    border: 2px solid #40df00;
  }

  .carousel-arrow.prev {
    left: -15px;
  }
  .carousel-arrow.prev:hover{
    border: 2px solid #40df00;

  }
  
  .carousel-arrow:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  