@font-face {
    font-family: "Centrale Sans Light";
    src: url("assets/custom/01.Centrale Sans/Centrale Sans Light.otf")
        format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Centrale Sans Bold";
    src: url("assets/custom/01.Centrale Sans/Centrale Sans Bold.otf")
        format("truetype");
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: "Centrale Sans Medium";
    src: url("assets/custom/01.Centrale Sans/Centrale Sans Medium.otf")
        format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Centrale Sans Regular";
    src: url("assets/custom/01.Centrale Sans/Centrale Sans Regular.otf")
        format("truetype");
    font-weight: normal;
    font-style: normal;
  }

#root{
    background-color: #ffffff;
}
#search-mobile {
    height: 31px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 2px;
    background-color: #F2F2F2;
}
#search-desk {
    border: #9b9b9b !important;
    background-color: #F2F2F2;
    height: 29px;
    padding: 0px;
}
#dateI {
    padding: 12.5px 14px;
    background-color: #fff;
    color: #0C2D9C;
    font-family: "Centrale Sans Medium";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
}
#dateF {
    padding: 12.5px 14px;
    background-color: #fff;
    color: #0C2D9C;
    font-family: "Centrale Sans Medium";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
}
#noCotInput {
    padding: 12.5px 14px;
    background-color: #fff;
    color: #0C2D9C;
    font-family: "Centrale Sans Medium";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
}

#precioDesdeInput {
    padding: 12.5px 14px;
    background-color: #fff;
    color: #0C2D9C;
    font-family: "Centrale Sans Medium";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
}
#precioHastaInput {
    padding: 12.5px 14px;
    background-color: #fff;
    color: #0C2D9C;
    font-family: "Centrale Sans Medium";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
}
.splide--nav>.splide__track>.splide__list>.splide__slide.is-active {
    border: 1px solid #40DF00 !important;
    border-radius: 3px;
}


::-webkit-scrollbar {
    width: 1px !important;
}