.quoteContainer {
   /* padding: 10px 0;*/
  }
  .quoteContainer .h-32 {
    height: 32px;
  }
  .quoteContainer .error-message {
    color: #DF2B2B;
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    margin-top: 1px;
  }
.message-success {
    color:#40DF00;
    font-size: 18px;
    font-weight: 600;
  }
  .quoteContainer .title {
    font-family: "M PLUS 2", sans-serif;
    font-weight: 500;
    font-size: 21px;
    padding: 30px 0;
    text-align: center;
    color: #0B3196;
  }
  .quoteContainer .subtitle {
    font-family: 'Centrale Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #0B3196;
  }

  .quoteContainer .card {
    background-color: #F5F5F5;
    border-radius: 0;
  }
  .quoteContainer .input {
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 2px;
    color: #949494;
  }
  .quoteContainer .input.error {
    border: 1px solid #DF2B2B;
  }
  .quoteContainer .text {
    font-family: 'Centrale Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;

    color: #444444;
  }
  
  .quoteContainer .btn {
    all: unset;
    background: #0B3196;
    border-radius: 3px;
    height: 37px;
    color: #FFFFFF;
    font-family: 'Centrale Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }

  .quoteContainer .btnOutline {
    all: unset;
    background: #FFFFFF;
    border: 1px solid #0B3196;
    border-radius: 3px;
    height: 35px;
    color: #0B3196;
    font-family: 'Centrale Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }
  .quoteContainer .return {
    font-family: 'Centrale Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: #0B3196;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .quoteContainer .w-100 {
    width: 100%;
  }
  .quoteContainer .px-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .quoteContainer .mb-5 {
    margin-bottom: 5px;
  }
  .quoteContainer .mb-2 {
    margin-bottom: 2px;
  }
  .quoteContainer .mb-4 {
    margin-bottom: 4px;
  }
  .quoteContainer .p-3 {
    padding: 3px;
  }
  .quoteContainer .p-4 {
    padding: 4px;
  }
  .quoteContainer .d-flex {
    display: flex;
  }
  .quoteContainer .align-items-center {
    align-items: center;
  }
  .quoteContainer .justify-content-center {
    justify-content: center;
  }
  .quoteContainer .form-card {
    padding: 5px;
  }
  .quoteContainer .ms-4 {
    margin-left: 4px;
  }
  .quoteContainer .me-2 {
    margin-right: 2px;
  }
  .quoteContainer .p-10 {
    padding: 10px;
  }
  .quoteContainer .my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .quoteContainer .modal-card {
    border-radius: 0;
    text-align: center;
  }
  .quoteContainer .modal-card .message {
    font-weight: bold;
    color: #000;
  }